<template>
  <div class="home">
    <div ref="hero"><HeroComponent /></div>
    <div ref="examines"><TestsComponent /></div>
    <div ref="fototerapy"><TerapyComponent /></div>
    <div ref="specialists"><SpecialistsComponent /></div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeroComponent from '@/components/HeroComponent.vue'
import TestsComponent from '@/components/TestsComponent.vue';
import SpecialistsComponent from '@/components/SpecialistsComponent.vue';
import TerapyComponent from '@/components/TerapyComponent.vue';
import { useSectionStore } from '@/store.js'

export default {
  name: 'HomeView',
  components: {
    HeroComponent,
    TestsComponent,
    TerapyComponent,
    SpecialistsComponent
  },

  computed: {
    section() {
      const sectionStore = useSectionStore()
      return sectionStore.section 
    }
  },
  watch: {
    async section(newValue) {
      await this.scrollTo(newValue)
    }
  },
  methods: {
  scrollTo(ref) {
    const behavior = this.isMobile ? 'auto' : 'smooth';
    
    if (ref === 'contact') {
      this.$emit('scroll-to-contact');
    }

    if (this.$refs[ref] && this.$refs[ref].$el) {
      this.$refs[ref].$el.scrollIntoView({
        behavior: behavior,
        block: 'start'
      });
    } else if (this.$refs[ref]) {
      this.$refs[ref].scrollIntoView({ behavior: behavior, block: 'start' });
    }
  }
}

  
}
</script>

<style scoped>

</style>