<template>
  <div class="overlay" @click.self="closePopup">
    <div class="popup">
      <button class="close-btn" @click="closePopup">&times;</button>
      <!-- Duży obrazek (placeholder) -->
      <img src="https://via.placeholder.com/600" alt="Placeholder image" class="popup-img">
      
      <!-- Teksty: nazwa i opis -->
      <h2 class="popup-title">{{ name }}</h2>
      <p class="popup-description">{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  methods: {
    closePopup() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped lang="scss">
@import './../main.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  
}

.popup {
  background: $light-color;
  width: 80%;
  height: 50rem;
  padding: 20px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.popup-img {
  width: 50%; /* Szerokość obrazka - możesz zmienić według potrzeb */
  height: 38rem;
  width: 57rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.popup-title {
  color: $dark-color;
  font-size: 2rem;
  margin-bottom: 10px;
  text-align: center;
}

.popup-description {
  color: $dark-color;
  font-size: 1.2rem;
  text-align: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: $main-color-dark;
  color: $light-color;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: $secendary-color;
}
</style>
