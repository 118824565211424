<template>
  <div 
    class="research-card" 
    v-bind="$attrs" 
    @mouseenter="isHovered = true" 
    @mouseleave="isHovered = false"
    @mouseup="openPopup(name, description)"
  >
    <div class="icon">
      <slot name="icon" :isHovered="isHovered"></slot>
    </div>
    <div class="content">
      <h3 :class="{ 'text-hover icon-svg': isHovered }">{{ name }}</h3>
    </div>
  </div>
  <Popup
      v-if="isPopupOpen"
      :name="popupName"
      :description="popupDescription"
      @close="closePopup"
    />
</template>

<script>
import Popup from './TestPopupComponent.vue';

export default {
  components: {
    Popup,
  },
  props: ['name', 'description'],
  inheritAttrs: false,  // Wyłącz automatyczne dziedziczenie atrybutów
  data() {
    return {
      isHovered: false,
      isPopupOpen: false,
      popupName: '',
      popupDescription: ''
    }
  },
  methods: {
    openPopup(name, description) {
      this.popupName = name;
      this.popupDescription = description;
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
    }
  }
}
</script>
  
 <style scoped lang="scss">

  @import './../main.scss';
.test-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10rem;
  height: auto;
  border-radius: 10px;
  text-align: center;
  }

  .icon {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 10rem;
  }

  .content{
    color: $main-color;
  }

  .research-card {
    cursor: pointer;
    padding: 1rem;
    padding-top: 3rem;
    background-color: rgba(244, 244, 242, 0.85);
    border-radius: 5px;
  }
  
  .tests-description {
    font-size: 1.4rem;
  }

  h3, p {
    margin-top: 2rem;
    text-align: center;
    color: $main-color-dark;
  }

  .text-hover {
    color: $secendary-color;
  }

</style>
