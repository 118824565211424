

<template>
  <NavComponent class="nav"/>
  <router-view @scroll-to-contact="scrollToContact" class="view"/>
  <div ref="contact"><FooterComponent  class="footer-container"/></div>

</template>

<script setup>
import NavComponent from './NavComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue'
import { ref } from 'vue';  



const contact = ref(null);  

const scrollToContact = () => {
  const behavior = 'smooth'; 

  if (contact.value) {
    contact.value.scrollIntoView({
      behavior: behavior,
      block: 'start' 
    });
  } else {
    console.error("Referencja do sekcji kontaktu jest niedostępna.");
  }
};

</script>

<style scoped lang="scss">

@import './main.scss';


  .view{
    padding-top: 7rem;
    max-width: $page-width;
    margin-left: auto;
    margin-right: auto;
  }



  .nav{
    position: fixed;
    z-index: 2;
    width: 100%;

  }
</style>