import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    message: 'Hello from Pinia!'
  }),
  actions: {
    updateMessage(newMessage) {
      this.message = newMessage
    }
  }
})

export const useSectionStore = defineStore('section', {
  state: () => ({
    section: 'default'
  }),
  actions: {
    setSection(newSection) {
      this.section = newSection
    }
  }
})