<template>
  <div class="space"></div>
    <h1>Specjaliści</h1>
    <div class="tests-content">
      
      <ResearchCard 
        v-for="(specialists, index) in specialists" 
        :key="index" 
        :name="specialists.name" 
        :description="specialists.description"
      >
        <!-- Używamy slotu z parametrem isHovered -->
        <template #icon="{ isHovered }">
          <component 
            :is="specialists.icon" 
            :fillColor="isHovered ? '#333466' : '#078b8b' " 
          />
        </template>
      </ResearchCard>
    </div>
  </template>
  
  <script>
  import ResearchCard from '@/components/ResearchCard.vue'
  

  import DoctorIcon from '@/assets/icons/DoctorIcon.vue'
  
  export default {
    components: {
      ResearchCard,
      DoctorIcon
    },
    setup() {
      return {
        hoverIndex: -1,
        specialists: [
          { name: 'Dermatolog', description: 'Opis badania Opis badania bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla', icon: 'DoctorIcon' },
          { name: 'Neurolog', description: 'Opis badania Opis badania bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla', icon: 'DoctorIcon' }
        ]
      }
    }
  }
  </script>
  
  
  <style scoped lang="scss">
  
  @import './../main.scss';
  
  
  
  .tests {
    width: 100%;  
    overflow: hidden;
    position: relative;
    
  }
  
  .tests img {
    width: 100%;
    clip-path: inset(0 0 13% 0); 
    transform: scale(1.3); 
    transform-origin: center 70%;
  }
  
  .tests-header{
    margin: 1rem;
  }
  
  
  
  .tests-content{
    margin-top: 1rem;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 20rem);
    justify-content: center;
    gap: 1rem;
  
  }
  
  h1{
    margin: 1rem;
    margin-top: 4rem;
    text-align: center;
  }
  
  
  </style>
  